@import "../../Theme/colors.less";

//desktop view
.AppFooter {
  &__Wrapper {
    align-content: center;
    border-top: 1px solid rgb(238, 239, 242);
    padding: 57px 0px 36px 0px;
  }

  &__shopeeSmallLogo {
    height: 36px;
    width: 181px;
  }

  &__itemNav {
    text-decoration: none;
    color: #000000;
    font-size: 14px;
  }

  &__termsTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: rgba(32, 35, 37, 0.6);
    opacity: 0.6;
    padding-bottom: 10px;
  }

  &__iconStyle {
    width: 60%;
    vertical-align: middle;
    border-style: none;
    box-sizing: border-box;
  }

  &__horizontalLine {
    width: 100%;
    border-top: solid #eeeff2 1.5px;
    padding-bottom: 16px;
    padding-top: 25px;
    text-align: center;
    font-size: 14px;
    color: #202325;
    opacity: 0.6;
    &__Footnote {
      color: @text__grey;
    }
  }

  &__Link {
    color: rgb(32, 35, 37);
    display: block;
    font-size: 14.5px;
    margin-bottom: 15px;
  }

  &__LeftBox {
    &__logo {
      margin-bottom: 50px;
      width: 113px;
    }

    & p {
      color: #202325;
      font-size: 14px;
      opacity: 60%;
    }

    &__socialMediaLogos {
      margin-bottom: 17px;
      margin-right: 20px;
    }

    &__appsLogo {
      margin-bottom: 27px;
      margin-right: 14px;
      width: 150px;
    }

    &__qr {
      margin-bottom: 30px;
      width: 150px;
    }

    &__downloadText {
      margin-bottom: 10px;
      align-items: left !important;
      text-align: left !important;
    }
  }

  &__RightBox {
    width: 420px;
  }

  &__WidthContainer {
    display: flex;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
  }

  &__RightUpperBox {
    display: grid;
    grid-column-gap: 109px;
    grid-template-columns: 180px 180px;
    grid-template-rows: 45px 200px;

    &__termsTitle {
      color: #202325;
      font-size: 14px;
      opacity: 60%;
      line-height: 24px;
    }

    &__Link {
      color: #202325;
      display: block;
      font-size: 14.5px;
      margin-bottom: 15px;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: color 0.3s;
    }
  }

  &__RightLowerBox {
    display: flex;
    flex-direction: column;

    &__termsTitle {
      color: #202325;
      font-size: 14px;
      opacity: 60%;
      margin-top: 32px;
      margin-bottom: 22px;
    }

    &__RightLogos {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      &__Logo {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // & a {
  //   background-color: transparent;
  //   outline: none;
  //   cursor: pointer;
  //   transition: color 0.3s;
  // }

  &__hashLink {
    text-decoration: "none";
    color: "#000000";
  }
}

//mobileView
.AppFooterMobile {
  .paddings {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  align-content: center;
  // border-top: 1px solid rgba(0, 0, 0, 0.14);
  height: auto;
  position: absolute;
  width: 100%;
  padding-bottom: 80px;

  &__TopBox {
    height: 108px;
    position: absolute;
    width: 100%;

    border-top: 1px solid rgba(0, 0, 0, 0.14);

    &__logo {
      margin-bottom: 25.91px;
      margin-left: 28px;
      margin-top: 48px;
      width: 30%;
    }
  }

  &__MiddleUpperBox {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    height: 437px;
    left: 28px;
    line-height: 24px;
    position: absolute;
    top: 126px;
    width: 315px;

    &__termsTitle {
      padding-bottom: 10px;
      color: @text__grey !important;

      &__topUp {
        color: @text__grey !important;
        padding-top: 20px;
      }
    }

    &__itemNavMobile {
      text-decoration: none;
      color: black !important;
      font-size: 14px !important;
    }
  }

  &__MiddleLowerBox {
    padding-top: 10px;
    margin-left: 28px;
    position: absolute;
    top: 504px;

    .top-up-stores {
      width: 60%;
    }

    &__socialMediaLogos {
      height: 36px;
      margin-right: 20px;
      width: 36px;
    }

    & p {
      color: #202325;
      font-size: 14px;
    }

    &__appsLogos {
      margin-bottom: 14.85px;
      margin-right: 16px;
      width: 40%;
    }

    &__MiddleLogos {
      margin-right: 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 60px;

      &__logo {
        margin-top: -2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__BottomBox {
    height: 80px;
    margin-bottom: 36px;
    position: absolute;
    top: 879px;
    width: 100%;
    justify-content: center;

    &__items {
      // margin-left:20px;
      text-align: start;
      padding-left: 20px;
    }

    &__divider {
      justify-items: center !important;
      padding: 10px 30px 27px 30px;
      text-align: center;
      font-size: 14px;
      opacity: 60%;
      color: #202325;

      &__Footnote {
        color: @text__grey;
      }

      &__horizontalLine {
        display: flex;
        justify-content: center;
        border-top: solid #5555557c 1px;
        padding-bottom: 27px;
        padding-top: 25px;
        text-align: center;
        font-size: 14px;
        color: @text__grey;
        opacity: 1;
        &__Terms {
          margin-top: 16px;
          color: @text__grey;

          a {
            color: inherit;
          }
          &__Footnote {
            color: @text__grey;
          }
        }
      }
    }
  }
}

@primary-color: rgb(238, 77, 45);