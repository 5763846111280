@import "../colors.less";
@import "~desktop";

.roboto_regular {
  font-family: "Roboto Regular";
}

.roboto_medium {
  font-family: "Roboto Medium";
}

.roboto_bold {
  font-family: "Roboto Bold";
}

.open_sans_regular {
  font-family: "Open Sans";
}

.open_sans_semi_bold {
  font-family: "Open Sans SemiBold";
}

.shopee_font {
  font-family: "Shopee 2021";
}

.shopee_font_regular {
  font-family: "Shopee_font";
}

.shopee_font_medium {
  font-family: "Shopee_font Medium";
}

.shopee_font_bold {
  font-family: "Shopee_font Bold";
}

.setFontDetails(@fontSize, @lineHeight:1.3) {
  font-size: @fontSize * 1px;
  line-height: @lineHeight * 1px;
}

.typographyRoot {
  color: @text__primary;
}

// for mobile view font
.typographyTitle {
  .setFontDetails(36, 48);
  color: @text__white;
}

.typographyMenuTitle {
  .setFontDetails(32, 48);
  color: @text__white;
}

.typographySubtitle {
  .setFontDetails(16, 28);
  color: @text__white;
}

.typographySubSubtitle {
  .setFontDetails(16, 21.79);
  color: @text__white;
}

.typographySelection {
  .setFontDetails(14, 14);
}

.typographyBottomBannerTitle {
  .setFontDetails(32, 48);
  color: @text__white;
}

.typographyHeadline {
  &__h1 {
    .setFontDetails(22, 32);
    font-family: "Shopee_font Bold";
  }

  &__h2 {
    .setFontDetails(16, 28);
    font-family: "Roboto Medium";
  }
}

.typographyBody1 {
  .setFontDetails(16, 28);
  color: @text__grey;
}

.typographyFootnote {
  .setFontDetails(14, 19);
  color: @text__grey;
  display: block;
}

.typographyFooterMenu {
  .setFontDetails(14, 24);
}

@media @desktop {
  .desktop {
    display: block;
  }

  .typographyMenu {
    .setFontDetails(14, 24);
  }

  .typographyTitle {
    .setFontDetails(60, 68);
    color: @text__white;
  }

  .typographyMenuTitle {
    .setFontDetails(60, 90);
    color: @text__white;
  }

  .typographySubtitle {
    .setFontDetails(22, 32);
    color: @text__white;
  }

  .typographySubSubtitle {
    .setFontDetails(16, 21.79);
    color: @text__white;
  }

  .typographySelection {
    .setFontDetails(20, 22);
  }

  .typographyBottomBannerTitle {
    .setFontDetails(36, 44);
    color: @text__white;
  }

  .typographyHeadline {
    &__h1 {
      .setFontDetails(32, 48);
      font-family: "Shopee_font Bold";
    }

    &__h2 {
      .setFontDetails(16, 28);
      font-family: "Roboto Medium";
    }
  }

  .typographyBody1 {
    .setFontDetails(16, 28);
  }

  .typographyFootnote {
    .setFontDetails(14, 28);
    color: @text__grey;
  }
}

@primary-color: rgb(238, 77, 45);