@import "~desktop";
@import "../../Theme/colors.less";

.LanguageToggle {
  display: inline-block;
  position: relative;
  width: 135px;
  height: 40px;
  background-color: @grey__border;
  border-radius: 20px;
  overflow: hidden;
  border-radius: 20px;
  font-size: 16px;
  &__Contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-left: 10px;
    margin-right: 20px;
  }

  input {
    position: absolute;
    display: block !important;
    width: 135px;
    height: 40px;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
  }

  input:checked + &__Knobs__MY:before {
    position: absolute;
    display: block;
    content: "EN";
    left: 85px;
  }

  &__Knobs__MY {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  &__Knobs__MY:before {
    content: "MY";
    position: absolute;
    top: 8px;
    left: 39px;
    width: 43px;
    height: 24px;
    color: @text__white;
    text-align: center;
    line-height: 1.5;
    background-color: @branding__primary;
    border-radius: 20px;
    transition: 0.3s ease all;
  }
}

@primary-color: rgb(238, 77, 45);